export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Orders',
    route: 'orders',
    icon: 'FileIcon',
  },
  {
    title: 'Postcode',
    icon: 'NavigationIcon',
    children: [
      {
        title: 'Postcode',
        route: 'postcode',
      },
      {
        title: 'Group',
        route: 'postcode-group',
      },
    ],
  },
  {
    title: 'User',
    route: 'user',
    icon: 'UserIcon',
  },
  {
    title: 'Category',
    icon: 'FolderPlusIcon',
    children: [
      {
        title: 'Category',
        route: 'category',
      },
      {
        title: 'Mac, Laptop & PC',
        route: 'mac-laptop-pc',
      },
      {
        title: 'Smart Home',
        route: 'smart-home-parts',
      },
      {
        title: 'Technical Support',
        route: 'technical-support',
      },
      {
        title: 'Business IT Support',
        route: 'business-it-support',
      },
      {
        title: 'Mobile & Tab',
        route: 'mobile-tablet',
      },
    ],
  },
  {
    title: 'Calendar',
    route: 'calendar',
    icon: 'MonitorIcon',
  },
  {
    title: 'Discounts',
    route: 'discounts',
    icon: 'MonitorIcon',
  },
  {
    title: 'Reports',
    route: 'customer-order',
    icon: 'MonitorIcon',
  },
  {
    title: 'Settings',
    route: 'settings',
    icon: 'SettingsIcon',
  },
]
