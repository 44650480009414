<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template
          v-if="USER"
          #button-content
        >
          <div
            class="d-sm-flex d-none user-nav"
          >
            <p class="user-name font-weight-bolder mb-0">
              {{ USER.name }}
            </p>
            <span class="user-status">{{ USER.role }}</span>
          </div>
          <b-avatar
            v-if="USER.avatar"
            size="40"
            variant="light-primary"
            badge
            :src="USER.avatar"
            class="badge-minimal"
            badge-variant="success"
          />
          <b-avatar
            v-else
            size="40"
            variant="light-primary"
            badge
            src="@/assets/images/pages/user/dummy-user.svg"
            class="badge-minimal p-75"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'user-profile' }"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{ name: 'user-update-password' }"
        >
          <feather-icon
            size="16"
            icon="LockIcon"
            class="mr-50"
          />
          <span>Password</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

const appModule = createNamespacedHelpers('app')
const userModule = createNamespacedHelpers('user')

export default {
  components: {
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...userModule.mapState(['USER']),
  },
  created() {
    this.FETCH_AUTH_USER()
  },
  methods: {
    ...appModule.mapMutations(['UPDATE_LOADER']),
    ...userModule.mapActions(['LOGOUT', 'FETCH_AUTH_USER']),
    async logout() {
      try {
        this.UPDATE_LOADER(true)
        const resp = await this.LOGOUT()
        if (resp) {
          this.$router.push('/login')
        }
        this.UPDATE_LOADER(false)
      } catch (error) {
        this.UPDATE_LOADER(false)
      }
    },
  },
}
</script>
